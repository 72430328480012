<template>
	<v-sheet color="transparent" max-width="1200" class=" pa-2 ma-auto h-100">
		<v-card outlined class="h-100">
			<v-card-title class="black text-h5 text-md-h4 white--text">
				Privacy Policy
			</v-card-title>
			<v-card-text class="">
				<div class="text-center mt-2">
					<router-link :to="{name: 'Dashboard'}">
						<v-img
							width="250"
							src="/img/logo_dark.png"
							class="mb-4 d-inline-flex"
							alt="logo"
						/>
					</router-link>
				</div>
				<div v-html="text" />
			</v-card-text>
		</v-card>
	</v-sheet>
</template>
<script>


export default {
	name: 'InfoPrivacyPolicy',
	components: {},
	computed: {},
	data: () => ({
		text: '',
	}),
	methods: {},
	created() {

		this.$store.dispatch('SETTINGS_APPROVALS/show', 'privacy-policy')
			.then(resp => {
				this.text = resp.data.data.text;
				this.loading = false
			})
			.catch(err => {
				console.error(err)
				this.loading = false
			});
	},
};
</script>
